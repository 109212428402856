import React from 'react'

export default function Product(props){
    let isLoggedIn
    if (props.q=="Coal") {
        isLoggedIn=true
      } else {
        isLoggedIn=false
      }
      
    return(
        <>
            <div className="product">
                <div className="product-img">
                    <img src={props.img} alt="" />
                </div>
                <div className="detail-wrap">
                    {props.q=="Coal" ? (
                     <h2 className="product-q">{Number(parseInt(localStorage.getItem(props.q)/1000))} </h2>
                    ): (
                        <h2 className="product-q">{Number(parseInt(localStorage.getItem(props.q))).toLocaleString("de-DE")} </h2>
                     
                    )}
                    <h3 className="product-title">{props.title}</h3>
                </div>
            </div>
        </>
    )
}