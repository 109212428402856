import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Home, ThirdPage, Fourth, Fifth, Sixth, Seventh, Eigth, Ninth, Tenth, ElevenPage, TwelvePage, FourtyPage } from "./components/pages";
import { FrFirstPage, FrThirdPage, FrFourthPage, FrFifthPage, FrSixthPage, FrSeventh, FrEigth, FrNinthPage, FrTenthPage, FrElevenPage, FrTwelvePage, FrFourtyPage } from "./components/fr";
import { PreviewPage, NlFirstPage, NlThirdPage, NlFourthPage, NlFifthPage, NlSixthPage, NlSeventh, NlEigth, NlNinthPage, NlTenthPage, NlElevenPage, NlTwelvePage, NlFourtyPage } from "./components/nl";

import NotFound from "./components/pages/NotFound";
import ThirthyPage from "./components/pages/ThirthyPage";


function App() {
  return (
    <Router>
      {/* <Nav /> */}
      <Routes>        
      
        <Route path="/" element={<Home />} />
        <Route path="/nuts" element={<ThirdPage />} />
        <Route path="/boom" element={<Fourth />} />
        <Route path="/huis" element={<Fifth />} />
        <Route path="/auto" element={<Sixth />} />
        <Route path="maand" element={<Seventh />} />
        <Route path="/jaar" element={<Eigth />} />
        <Route path="ninth-page" element={<Ninth />} />
        <Route path="meteo" element={<Tenth />} />
        <Route path="speedo" element={<ElevenPage />} />
        <Route path="zonop" element={<TwelvePage />} />
        <Route path="daken" element={<ThirthyPage />} />
        <Route path="alles" element={<FourtyPage />} />
        {/* France */}
        <Route path="/fr/" element={<FrFirstPage />} />
        <Route path="/fr/nuts" element={<FrThirdPage />} />
        <Route path="/fr/boom" element={<FrFourthPage />} />
        <Route path="/fr/huis" element={<FrFifthPage />} />
        <Route path="/fr/auto" element={<FrSixthPage />} />
        <Route path="/fr/maand" element={<FrSeventh />} />
        <Route path="/fr/jaar" element={<FrEigth />} />
        <Route path="/fr/ninth-page" element={<FrNinthPage />} />
        <Route path="/fr/meteo" element={<FrTenthPage />} />
        <Route path="/fr/speedo" element={<FrElevenPage />} />
        <Route path="/fr/zonop" element={<FrTwelvePage />} />
       
        {/* Nl */}
        <Route path="/nl/PreviewPage" element={<PreviewPage />} />
        <Route path="/nl" element={<NlFirstPage />} />
        <Route path="/nl/nuts" element={<NlThirdPage />} />
        <Route path="/nl/boom" element={<NlFourthPage />} />
        <Route path="/nl/huis" element={<NlFifthPage />} />
        <Route path="/nl/auto" element={<NlSixthPage />} />
        <Route path="/nl/maand" element={<NlSeventh />} />
        <Route path="/nl/jaar" element={<NlEigth />} />
        <Route path="/nl/ninth-page" element={<NlNinthPage />} />
        <Route path="/nl/meteo" element={<NlTenthPage />} />
        <Route path="/nl/speedo" element={<NlElevenPage />} />
        <Route path="/nl/zonop" element={<NlTwelvePage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
);
}

export default App;
