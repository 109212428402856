import Boxes from "../Boxes";
import Sidebar from "../template/Sidebar";
import PlaceHolder from '../../assets/img/nl/nl-floating.jpeg'
import { useHomeQueryParameter } from "../../hooks/getQueryParam";
import boxImg from '../../assets/img/solar.png'
import ClockSVG from "../template/ClockSVG";
export default function PreviewPage(){
    var MainHomeClasses= useHomeQueryParameter()
     
    return(
        <>
            <div className={MainHomeClasses} style={{'background-image': 'url(https://applicationaddons.com/energypanelfiles/previewpage/bgimg.jpg)'}}>
                <div className="shadow"></div>
                <div className="grid full">

                    <div className="page__sidebar"> 
            <div className="page__sidebar_header">
                <h2 className="clock-title bold">Dit gebouw produceert zonne-energie</h2>
            </div>
            <h1 style={{color: 'white', alignSelf: 'flex-end'}} className="bold text-center">Showroom</h1>
            <div className="page__sidebar_content">
                <ClockSVG />
            </div>
            <h1 style={{color: 'white'}} className="bold text-center">Location</h1>
            <div className="page__sidebar_footer">
                
                <img
                    src='https://applicationaddons.com/energypanelfiles/previewpage/logo.jpg'
                    alt=""
                    className={`sidebar-float nl`}
                />
                


            </div>
        </div>
                    <div className="page__content">
                        <h2 className="title">Energie van zonlicht</h2>
                        <div className="space-50"></div>
                        <div className="grid-3" id="boxes">
                            <div className="box">
                            <h3 className="box-title"> Dagopbrengst</h3>
                            <div className="details">
                            <img className="box-image" src={boxImg} alt="" />
                            <p className="box-subtitle">227,00<span> kWh</span></p>
                            </div>
                            </div>

                            <div className="box">
                            <h3 className="box-title"> Maandopbrengst</h3>
                            <div className="details">
                            <img className="box-image" src={boxImg} alt="" />
                            <p className="box-subtitle">32.223,10<span> kWh</span></p>
                            </div>
                            </div>

                            <div className="box">
                            <h3 className="box-title"> Jaaropbrengst</h3>
                            <div className="details">
                            <img className="box-image" src={boxImg} alt="" />
                            <p className="box-subtitle">70,977<span> MWh</span></p>
                            </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
