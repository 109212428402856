import React from 'react';
import Sidebar from "../template/Sidebar";
import PlaceHolder from '../../assets/img/logo-left.jpg';
import RightImg from '../../assets/img/logo-right.png';
import Circle from '../../assets/img/circle.png';

export default function FrElevenPage(){

    return(
        <>
            <div className="page full-screen background eleven">
                <div className="shadow"></div>
                <div className="grid">
                    <Sidebar title="Durable grâce à l'énergie solaire" placeholder={PlaceHolder} showtext='Y'/>
                    <div className="page__content">
                        <div className="box light">
                            <div className="box__content">
                                <img src={Circle} className="circle speedoLowScreen" alt="" />
                                <div className="wraper">
                                    <div className="text-wrapper">
                                        <h2>Réduction de CO<sub>2</sub> 2023: 1202 tonnes en contre 2.995,601 MWh</h2>
                                        <h2>&nbsp;</h2>
                                    </div>
                                </div>
                                <img className="right_logo" src={RightImg} alt="right"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}