import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

const useSummaryApiData = (URL, body) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const param = useMemo(() => {
    return { id: body.id, lang: body.ln };
  }, [body.id, body.ln]);

  useEffect(() => {
    if (param.id== undefined){
      return
    }
    if (localStorage.getItem("Fuel oil")!=null){
      const res=[]
      setData(res);
      setLoading(false);
      return
    }
      axios.post("https://applicationaddons.com/api/GetEnergyStatisticsSummary", param)
      .then(resp => {
        if (resp.status === 200) {
          const res = JSON.parse(resp.data.data);
          const resExtra = JSON.parse(resp.data.dataExtra);
          resExtra.forEach(item => {
            localStorage.setItem("bgImage",item['bgImage'])
            localStorage.setItem("logoFR",item['logoFR'])
            localStorage.setItem("logoNL",item['logoNL'])
           });
          res.forEach(item => {
           //localStorage.setItem(item['StatisticsCode'],Number(parseFloat(item['TotalValue'])).toLocaleString("de-DE", {minimumFractionDigits: 2}))
           localStorage.setItem(item['StatisticsCode'],item['TotalValue'])
          });
          
          const objres= res.filter(o=>o.EnergyStatisticsId==12)
          let TotalValue= parseFloat(objres[0].TotalValue)
          let km_value= (TotalValue/40074).toFixed(2)
          localStorage.setItem("km_value",Number(parseFloat(km_value)).toLocaleString("de-DE", {minimumFractionDigits: 2}))
          setData(res);
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [URL, param]);

  return { data, loading, error };
};

export default useSummaryApiData;
