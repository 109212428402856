
const data = [
    {
        title: 'Daily yield',
    },
    {
        title: 'Monthly yield',
    },
    {
        title: 'Annual yield',
    }
]

const frData = [
    {
        title: 'Rendement du jour',
    },
    {
        title: 'Rendement mensuel',
    },
    {
        title: 'Rendement annuel du parc solaire de Facq 2023',
    }
]

const NlData = [
    {
        title: 'Dagopbrengst',
    },
    {
        title: 'Maandopbrengst',
    },
    {
        title: 'Jaaropbrengst zonnepark Facq 2023',
    }
]

const product1 = [
    {
        price: 8.618,
        img: '/assets/imgs/fridge.png'
    },
    {
        price: 5.387,
        img: '/assets/imgs/washingMachine.png'
    },
    {
        price: 2.122,
        img: '/assets/imgs/table-fan.png'
    },
    {
        price: 120,
        img: '/assets/imgs/stove.png'
    }
]

const product2 = [

    {
        q: localStorage.getItem("Fuel oil")+' l',
        title: "Fuel oil",
        img: '/assets/imgs/stookolie.png'
    },
    {
        q: `159.321 m3;`,
        title: 'Natural Gas',
        img: '/assets/imgs/Aardgas.png'
    },
    {
        q: '113 t',
        title: 'Coal',
        img: '/assets/imgs/Steenkool.png'
    }

]
const productNL = [

    {
        q: "Fuel oil",// Number(parseInt(localStorage.getItem("Fuel oil"))).toLocaleString("de-DE")+' l',
        title: "Stookolie Liters",
        img: '/assets/imgs/stookolie.png',
        symbol:'l'
    },
    {
        q: 'NaturalGas',//Number(parseInt(localStorage.getItem("NaturalGas"))).toLocaleString("de-DE") +` m3`,
        title: 'Aardgas / m3',
        img: '/assets/imgs/Aardgas.png',
        symbol:'m3'
    },
    {
        q: 'Coal', //Number(parseInt(localStorage.getItem("Coal")/1000))+' t',
        title: 'Steenkool / Tonne',
        img: '/assets/imgs/Steenkool.png',
        symbol:'t'
    }

]
const productFR = [

    {
        q: "Fuel oil",//Number(parseInt(localStorage.getItem("Fuel oil"))).toLocaleString("de-DE")+' l',
        title: "Mazout Liters",
        img: '/assets/imgs/stookolie.png',
        symbol:'l'
    },
    {
        q: "NaturalGas",//Number(parseInt(localStorage.getItem("NaturalGas"))).toLocaleString("de-DE") +` m3`,
        title: 'Gaz Naturel / m3',
        img: '/assets/imgs/Aardgas.png',
        symbol:'m3'
    },
    {
        q: "Coal",//Number(parseInt(localStorage.getItem("Coal")/1000))+' t',
        title: 'Charbon / Tonne',
        img: '/assets/imgs/Steenkool.png',
        symbol:'t'
    }

]


export function getProduct2(){
    return product2;
}

export function getProductNL(){
    return productNL;
}
export function getProductFR(){
    return productFR;
}

export function getProduct1(){
    return product1;
}

export function getFrData(){
    return frData;
}

export function getNlData(){
    return NlData;
}

export function getData(){
    return data;
}