import React, {useState, useEffect} from 'react';
import Product2 from '../template/Product2';
import Sidebar from "../template/Sidebar";
import Copyright from '../template/Copyright';
import { getProductFR } from '../../data';
import PlaceHolder from '../../assets/img/fr/fr-floating.jpeg';
import useQueryParameter from '../../hooks/getQueryParam';
import useQuery from '../../hooks/useQuery';
import useSummaryApiData from '../../hooks/useSummaryApiData';
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
export default function FrThirdPage(){
    var MainHomeClasses= useQueryParameter()
    const CurrentDate= `${new Date().getDate()}/${months[new Date().getMonth()]}/${new Date().getFullYear()}`
    const paramValue = useQuery("fr");
    const { data, loading, error } = useSummaryApiData(URL, paramValue);
    const [data1, setData] = useState([])

    useEffect(()=>{
        let result = data; 
        if (result.length>=0){ 
            setData(getProductFR())
        } 
    }, [data,loading])

    return(
        <>
            <div className={MainHomeClasses} style={{'background-image': 'url('+localStorage.getItem('bgImage')+')'}}>
                <div class="shadow"></div>
                <div className="grid">
                    <Sidebar ln="fr" placeholder={PlaceHolder}/>
                    <div className="page__content">
                        <div className="box light">
                            <div className="box__header">
                                <h2 className="title colored">CO&#8322; économisé</h2>
                                <h3 className="sub-title">
                                <div className='row'>
                                        <div className='col-lg-9'>L'énergie solaire produite par la Facq sur les 12 derniers mois</div>
                                        <div className='col-lg-3' style={{'text-align': 'end'}}>{CurrentDate}</div>
                                    </div>
                                </h3>
                                <div className="line"></div>
                            </div>
                            <div className="box__content">

                                <div className="wrapper">
                                    <h3 className="vh3">CO<sub>2</sub> économisé 2023: {parseInt(localStorage.getItem('CO2'))} t</h3>
                                    <div className="product-wrap" style={{alignItems: "flex-end"}}>
                                        {!loading && data.length >= 0 && data1.map( item => {
                                            return(
                                                <Product2 q={item.q} title={item.title} img={item.img} />
                                            )
                                        } )}
                                    </div>                                    
                                </div>
                                <Copyright ln="fr" />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}