import React from 'react';
import Chart from 'react-apexcharts'
import useQuery from '../../hooks/useQuery';
import Sidebar from '../template/Sidebar';
import Copyright from '../template/Copyright';
import PlaceHolder from '../../assets/img/nl/nl-floating.jpeg'
import useChartData from '../../hooks/useChartData';
import useChart from '../../hooks/useChart';
import useQueryParameter from '../../hooks/getQueryParam';

const URL = "https://applicationaddons.com/api/GetEnergyYearWiseChart"

const cacheKey = 'nl_ninth_cache_data'

export default function NlNinthPage(){
    var MainHomeClasses= useQueryParameter()
    const chartOptions = {
        id: 'ninth-page',
        colors: ["#ffd800", "transparent"],
        width: '100%',
        height:'auto',
        enabled: false,
        name: 'Dit gebouw produceert zonne-energie!',
        title: `Jaar: ${new Date().getFullYear().toString()}`
    }

    const paramValue = useQuery('nl');
    const { data, loading, error } = useChartData(URL, paramValue, cacheKey);
    const { options, series } = useChart(data, loading, chartOptions);

    return(
        <>
            <div className={MainHomeClasses} style={{'background-image': 'url('+localStorage.getItem('bgImage')+')'}}>
                <div className="shadow"></div>
                <div className="grid">
                    <Sidebar ln="nl" placeholder={PlaceHolder} />
                    <div className="page__content">
                        <div className="box light">
                            <div className="box__header">
                                <h2 className="title colored">Vermeden Co<sub>2</sub> uitstoot: {parseInt(localStorage.getItem('CO2'))} t</h2>
                                <h3 className="sub-title">Facq gaat voor hernieuwbare energie</h3>
                                <div className="line"></div>
                            </div>
                            <div className="wrap flex align-center Chartlowscreen">

                                
                                { !loading && <Chart options={options} series={series} type="bar" width={"100%"} height={'320'} /> }
                                <Copyright ln="nl" />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
