import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

export default function useQuery(ln) {
  const [paramValue, setParamValue] = useState();
  const [searchParam, setSearchParam] = useSearchParams();
  const id = searchParam.get("id");
  const name = searchParam.get("location");

  useEffect(() => {
    setParamValue(id);
    setParamValue(name);
  }, [id,name, ln]); 

  return { id: id, lang: ln , name:name};
}