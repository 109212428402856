import React from 'react';
import Sidebar from "../template/Sidebar";
import Copyright from '../template/Copyright';
import Tree from '../../assets/img/tree.png'

import PlaceHolder from '../../assets/img/fr/fr-floating.jpeg';
import useQueryParameter from '../../hooks/getQueryParam';
import useQuery from '../../hooks/useQuery';
import useSummaryApiData from '../../hooks/useSummaryApiData';
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
export default function FrFourthPage(){
const CurrentDate= `${new Date().getDate()}/${months[new Date().getMonth()]}/${new Date().getFullYear()}`
var MainHomeClasses= useQueryParameter()
const paramValue = useQuery("fr");
const { data, loading, error } = useSummaryApiData(URL, paramValue);
return(
    <>
        <div className={MainHomeClasses} style={{'background-image': 'url('+localStorage.getItem('bgImage')+')'}}>
            <div class="shadow"></div>
            <div className="grid">
                <Sidebar ln="fr" placeholder={PlaceHolder}/>
                <div className="page__content">
                    <div className="box light">
                        <div className="box__header">
                            <h2 className="title colored">CO&#8322; économisé</h2>
                            <h3 className="sub-title">
                            <div className='row'>
                                <div className='col-lg-9'>L'énergie solaire produite par la Facq sur les 12 derniers mois</div>
                                        <div className='col-lg-3' style={{'text-align': 'end'}}>{CurrentDate}</div>
                                        </div>
                            </h3>
                            <div className="line"></div>
                        </div>
                        
                        <div className="wrap">
                        <div className="row">
                        <div className="col-xs-12 col-sm-12">
                        <h3 className='vh3'  >CO<sub>2</sub> économisé 2023: {parseInt(localStorage.getItem('CO2'))} t</h3>
                        </div>
                        </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-6 mr-0 pr-0">
                                    <p  class="m-0 p-0 pfourth-Page" >
                                        {/* Le montant de CO&#8322; économisé est équivalent a environ 11 */}
                                        Pour retirer la méme quantité de CO&#8322; de l'atmosphére dans 10 ans il acerait foudrait planter <b>{Number(parseInt(localStorage.getItem("Trees"))).toLocaleString("de-DE")}</b> arbres.</p>
                                </div>
                                <div className="col-xs-12 col-sm-6 text-center">
                                    <img style={{width: "80%"}} src={Tree} alt="" />
                                </div>
                            </div>

                            <p className="box-note">80 arbres(soit 100 m&sup2; de forêt) adsorbent en un an environs 1 tonne de CO2 présent dans l'atmosphére.</p>
                        </div>

                        <Copyright ln="fr" />
                            
                    </div>
                </div>
            </div>

        </div>
    </>
)
}