import React from 'react';
import Sidebar from "../template/Sidebar";
import Copyright from '../template/Copyright';
import Car from '../../assets/img/car-worldicon.png'

import PlaceHolder from '../../assets/img/nl/nl-floating.jpeg';
import useQueryParameter from '../../hooks/getQueryParam';
import useQuery from '../../hooks/useQuery';
import useSummaryApiData from '../../hooks/useSummaryApiData';

export default function NlSixthPage(){
    var MainHomeClasses= useQueryParameter()
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const CurrentDate= `${new Date().getDate()}/${months[new Date().getMonth()]}/${new Date().getFullYear()}`
    const paramValue = useQuery("nl");
    const { data, loading, error } = useSummaryApiData(URL, paramValue);
    return(
        <>
            <div className={MainHomeClasses} style={{'background-image': 'url('+localStorage.getItem('bgImage')+')'}}>
                <div class="shadow"></div>
                <div className="grid">
                    <Sidebar ln="nl" placeholder={PlaceHolder}/>
                    <div className="page__content">
                        <div className="box light">
                            <div className="box__header">
                                <h2 className="title colored">Vermeden CO&#8322; uitstoot</h2>
                                <h3 className="sub-title">
                                <div className='row'>
                                    <div className='col-lg-9'>Heel Facq samen produceert zonne-energie!</div>
                                        <div className='col-lg-3' style={{'text-align': 'end'}}>{CurrentDate}</div>
                                        </div>
                                </h3>
                                <div className="line"></div>
                            </div> 
                            <div className="wrap">
                                <div className="wrapper">
                                    <h3 className='bold mb-4'>Bespaarde CO<sub>2</sub> in 2023: {parseInt(localStorage.getItem('CO2'))} t</h3>
                                    <div className="row" style={{height: "90%"}}>
                                        <div className="col-xs-12 col-sm-6">
                                            <p style={{fontWeight: 500}} className='mb-4 pfourth-Page'>De vermeden CO&#8322;-uitstoot komt ongeveer overeen met {localStorage.getItem('km_value')} maal de wereldomtrek met een (kleine auto).</p>
                                            <p style={{fontWeight: 500}} className="mb-2 pfourth-Page">Afgelegde afstand: {Number(parseInt(localStorage.getItem("KM"))).toLocaleString("de-DE")} km</p>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <img style={{width: "100%"}} src={Car} className='car-globe' alt="" />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="wrapper">
                                <p className='mb-2'>Aardeomtrek 40.074 km</p>
                                <Copyright ln="nl"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}