import React, {useEffect, useState} from 'react'; 

import Img1 from '../../assets/img/thirty-page/brugge.png'
import Img2 from '../../assets/img/thirty-page/geel.png'
import Img3 from '../../assets/img/thirty-page/oostende.png'
import Img4 from '../../assets/img/thirty-page/zaventem_logistics.png'
import Img5 from '../../assets/img/thirty-page/arlon.png'
import Img6 from '../../assets/img/thirty-page/zaventem_showroom.png'

function ThirthyPage() {
    let countDown =0;
    const [seconds, setSeconds] = useState(12)
      useEffect(() => {
        if (seconds <= 0) {
            return;
        }
        const timer = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(timer);
      }, [ seconds]);
  return (
    <>
    
     
    { seconds >= 6 ?(
                
            <div className='full-page flex flex-direction-column' style={{background: '#16A085', padding: '10px',minHeight: '100vh'}}>                
            <div className="row">
                <div className="col-xs-12 col-sm-6 flex">
                    <div className="img-col-wrap">
                        <h3 className='text-center'>Brugge</h3>
                        <img style={{height: 'calc(50vh - 80px)'}} src={Img1} alt="" />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 flex">
                    <div className="img-col-wrap">
                        <h3 className='text-center'>Geel</h3>
                        <img style={{height: 'calc(50vh - 80px)'}} src={Img2} alt="" />
                    </div>
                </div>
               
            </div>
            
            <div className='row'>
            <div className="col-xs-12 col-sm-6 flex">
                    <div className="img-col-wrap">
                        <h3 className='text-center'>Oostende</h3>
                        <img style={{height: 'calc(50vh - 80px)'}} src={Img3} alt="" />
                    </div>
                </div>
               
                <div className="col-xs-12 col-sm-6 flex">
                    <div className="img-col-wrap">
                        <h3 className='text-center'>Zaventem Logistics</h3>
                        <img style={{height: 'calc(50vh - 80px)'}} src={Img4} alt="" />
                    </div>
                </div>
            </div>
                           
            </div> 
): 
            <div className='full-page flex flex-direction-column' style={{background: '#16A085', padding: '10px',minHeight: '100vh'}}>                
            <div className="row">
                <div className="col-xs-12 col-sm-6 flex">
                    <div className="img-col-wrap">
                        <h3 className='text-center'>Arlon</h3>
                        <img style={{height: 'calc(50vh - 80px)'}} src={Img5} alt="" />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 flex">
                    <div className="img-col-wrap">
                        <h3 className='text-center'>Zaventem Showroom</h3>
                        <img style={{height: 'calc(50vh - 80px)'}} src={Img6} alt="" />
                    </div>
                </div>
               
            </div>
            
            
                           
            </div>
   }           
        </>
  )
}

export default ThirthyPage