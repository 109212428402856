import classNames from 'classnames';
import { useSearchParams } from "react-router-dom";
export default function useQueryParameter() {
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");

  var MainHomeClasses = classNames(
    'full-screen',
    'background',
    {
      'page': id=="1",
      'page_Hasselt': id=="2"
    }
  );
  return MainHomeClasses;
}
export  function useHomeQueryParameter() {
    const [searchParam] = useSearchParams();
    const id = searchParam.get("id");
  
    var MainHomeClasses = classNames(
      'full-screen',
      'background',
      'home',
      {
        'page': id=="1",
        'page_Hasselt': id=="2"
      }
    );
    return MainHomeClasses;
  }