import React from 'react';
import Sidebar from "../template/Sidebar";
import Copyright from '../template/Copyright';

import PlaceHolder from '../../assets/img/nl/nl-floating.jpeg';
import Sun from '../../assets/img/eart_sun.png';
import useQueryParameter from '../../hooks/getQueryParam';
import useQuery from '../../hooks/useQuery';

export default function NlTwelvePage(){
    var MainHomeClasses= useQueryParameter()
    const paramValue = useQuery("nl");
    const toTitleCase = (s)=>{
        if (typeof(s)==='string'&&s.length>0) {
          const words = s.split(' ')
          if (Array.isArray(words)&&words.length>0) {
            if (words.length===1) {
              const word = words[0]
              const matches = word.charAt(0).match(/\w+/i)
              const lines = word.split('\n')
              if (Array.isArray(lines)&&lines.length>1) {
                return lines.map(line=>{
                  return toTitleCase(line)
                }).join('\n')
              } else if (Array.isArray(matches)) {
                return word.split('').map((c,i)=>{
                  if (i===0) {
                    return c.toUpperCase()
                  }
                  return c.toLowerCase()
                }).join('')
              } else {
                return word.charAt(0).concat(toTitleCase(word.slice(1)))
              }
            } else {
              return words.map(word=>toTitleCase(word)).join(' ')
            }
          }
        }
        return ''
      }
    return(
        <>
            <div className={MainHomeClasses} style={{'background-image': 'url('+localStorage.getItem('bgImage')+')'}}>
                <div className="shadow"></div>
                <div className="grid">
                    <Sidebar ln="nl" placeholder={PlaceHolder}/>
                    <div className="page__content">
                        <div className="box light">
                            <div className="box__header">
                                <h3 className="sub-title">Facq: klaar voor de toekomst</h3>
                                <h2 className="title colored">Stad: {toTitleCase(paramValue.name)}</h2>
                                <div className="line"></div>
                            </div> 
                            <div className="wrap">
                                <div className="wrapper twelve-grid">
                                    <div className="grid-col">
                                        <div className="text-wrapper">
                                            <h2 className="text-wrapper-title">Zenit</h2>
                                            <p className="text-wrapper-para">12:35</p>
                                        </div>
                                    </div>
                                    <div className="grid-col">
                                        <div className="text-wrapper">
                                            <h2 className="text-wrapper-title">Zonsopgang</h2>
                                            <p className="text-wrapper-para">8:15</p>
                                        </div>
                                        <div className="image-wrapper">
                                            <img src={Sun} alt="" />
                                        </div>
                                    </div>
                                    <div className="grid-col">
                                        <div className="text-wrapper">
                                            <h2 className="text-wrapper-title">Zonsondergang</h2>
                                            <p className="text-wrapper-para">16:55</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="wrapper">
                                <Copyright ln="nl" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}