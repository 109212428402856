import React, { useEffect, useState } from 'react';
import useQuery from '../../hooks/useQuery';
import Chart from 'react-apexcharts';
import Sidebar from "../template/Sidebar";
import Copyright from '../template/Copyright';
import useChartData from '../../hooks/useChartData';
import useChart from '../../hooks/useChart';

import PlaceHolder from '../../assets/img/nl/nl-floating.jpeg';
import useQueryParameter from '../../hooks/getQueryParam';

const URL = "https://applicationaddons.com/api/GetEnergyMonthChart";

const months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];

const cacheKey = 'nl_seventh_data';

export default function NlSeventh() {
    var MainHomeClasses= useQueryParameter()
    const chartOptions = {
        id: 'seventh-page',
        colors: ["#ffd800", "transparent"],
        width: '100%',
        height: 'auto',
        enabled: false,
        name: 'Uitgangsvermogen',
        title: `${months[new Date().getMonth()]} : ${new Date().getDate()}`
    };

    const paramValue = useQuery('nl');
    
    const { data, loading, error } = useChartData(URL, paramValue, cacheKey);
    const { options, series } = useChart( data , loading, chartOptions);

    useEffect(()=>{
        console.log( "Data: ")
        console.log( data )
    })

    return (
        <>
            <div className={MainHomeClasses} style={{'background-image': 'url('+localStorage.getItem('bgImage')+')'}}>
                <div className="shadow"></div>
                <div className="grid">
                    <Sidebar ln="nl" placeholder={PlaceHolder} showtext='Y'/>
                    <div className="page__content">

                        <div className="box light">
                            <div className="box__header">
                                <h2 className="title colored">Maandoverzicht</h2>
                                <h3 className="sub-title">Dit gebouw produceert zonne-energie!</h3>
                                <div className="line"></div>
                            </div>
                            <div className="wrap align-center Chartlowscreen">
                                
                                {!loading && <Chart options={options} series={series} type="bar" width={"100%"} height={'320'} />}
                            </div>
                            <Copyright ln="nl" />
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}
