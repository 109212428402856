import Boxes from "../Boxes";
import Sidebar from "../template/Sidebar";
import PlaceHolder from '../../assets/img/fr/fr-floating.jpeg';
import { useHomeQueryParameter } from "../../hooks/getQueryParam";
import useQuery from "../../hooks/useQuery";
import useApiData from "../../hooks/useApiData";
import Box from "../Box";
import { useEffect, useState } from "react";
import { getFrData, getNlData, getData } from "../../data";
import getCookies from "../../hooks/getCookies";
import setCookie from "../../hooks/setCookie";

export default function FrFirstPage(){
    const URL = "https://applicationaddons.com/api/GetEnergySummary";
    const paramValue = useQuery("fr");
    const { data, loading, error } = useApiData(URL, paramValue);
    const [title, setTitle] = useState(getData())
    const [cookieData, setCookieData] = useState([]);
    var MainHomeClasses= useHomeQueryParameter()
     useEffect(() => {
        setCookieData(getCookies());
        setTitle(getFrData())
      },[]);     
     useEffect(() => {
        if (cookieData.length === 0 && !loading) {
    
            let result = data;
            result.map((item) => {
              return setCookie(item.title, item.speed);
            });
      
            setCookieData(result); // Update cookieData after setting the cookies
        }
      }, [ data, loading]);
  return(
      <>
      {loading ? <p></p> :
          <div className={MainHomeClasses} style={{'background-image': 'url('+localStorage.getItem('bgImage')+')'}}>
              <div className="shadow"></div>
              <div className="grid full">
                  {/* Side bar starts */}
                  <Sidebar ln="fr" placeholder={PlaceHolder} showtext='Y'/>
                  <div className="page__content">
                      <h2 className="title">Production solaire</h2>
                      <div className="space-50"></div>
                      <div className="grid-3" id="boxes">
        {!loading && data.length === 0 && cookieData.map((item, idx) => {
          return <Box key={idx} title={title[idx].title} speed={item.speed} />;
        })}
        {!loading && data.length >= 0 && data.map((item, idx) => {
          return <Box key={idx} title={title[idx].title} speed={item.speed} />;
        })}
      </div>
                  </div>
              </div>
          </div>
}
      </>
  )
}
