import React from 'react';
import Sidebar from "../template/Sidebar";
import Copyright from '../template/Copyright';
import Chart from 'react-apexcharts'
import useChartData from '../../hooks/useChartData';
import useChart from '../../hooks/useChart';
import PlaceHolder from '../../assets/img/nl/nl-floating.jpeg'
import useQuery from '../../hooks/useQuery';
import useQueryParameter from '../../hooks/getQueryParam';

const URL = "https://applicationaddons.com/api/GetEnergyMonthWiseChart"

export default function NlEigth(){
    var MainHomeClasses= useQueryParameter()
    const chartOptions = {
        id: 'eight-page',
        colors: ["#ffd800", "transparent"],
        width: '100%',
        height:'auto',
        enabled: false,
        name: 'Uitgangsvermogen',
        title: `Jaar: ${new Date().getFullYear().toString()}`
    }

    const paramValue = useQuery('nl');
    const { data, loading, error } = useChartData(URL, paramValue);
    const {options, series } = useChart(data, loading, chartOptions);

    return(
        <>
            <div className={MainHomeClasses} style={{'background-image': 'url('+localStorage.getItem('bgImage')+')'}}>
                <div className="shadow"></div>
                <div className="grid">
                    <Sidebar ln="nl" placeholder={PlaceHolder} showtext='Y'/>
                    <div className="page__content">
                        <div className="box light">
                            <div className="box__header">
                                <h2 className="title colored">Jaaroverzicht</h2>
                                <h3 className="sub-title">Dit gebouw produceert zonne-energie!</h3>
                                <div className="line"></div>
                            </div>
                            
                            <div className="wrap align-center Chartlowscreen">
                                {!loading && <Chart options={options} series={series} type="bar" width={"100%"} height={'320'} /> }  
                                <Copyright ln="nl" />  

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}