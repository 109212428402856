import PlaceHolder from '../../assets/img/floating-img.jpg';
import ClockSVG from "../template/ClockSVG";
import NlPlaceHolder from '../../assets/img/nl/nl-floating.jpeg'
import FrPlaceHolder from '../../assets/img/fr/fr-floating.jpeg'
import useQuery from '../../hooks/useQuery';

export default function Sidebar({ ln, placeholder, title,showtext }){
    const paramValue = useQuery('fr'); 
    const toTitleCase = (s)=>{
        if (typeof(s)==='string'&&s.length>0) {
          const words = s.split(' ')
          if (Array.isArray(words)&&words.length>0) {
            if (words.length===1) {
              const word = words[0]
              const matches = word.charAt(0).match(/\w+/i)
              const lines = word.split('\n')
              if (Array.isArray(lines)&&lines.length>1) {
                return lines.map(line=>{
                  return toTitleCase(line)
                }).join('\n')
              } else if (Array.isArray(matches)) {
                return word.split('').map((c,i)=>{
                  if (i===0) {
                    return c.toUpperCase()
                  }
                  return c.toLowerCase()
                }).join('')
              } else {
                return word.charAt(0).concat(toTitleCase(word.slice(1)))
              }
            } else {
              return words.map(word=>toTitleCase(word)).join(' ')
            }
          }
        }
        return ''
      }
    let tag = "This building produces solar energy"
    if(ln === "fr"){
        tag = "Le parc solaire Facq";
    }
    if(ln === "nl"){
        tag = "Facq produceert zonne-energie";
    }

    if( title ){
        tag = title;
    }

    return(
        <div className="page__sidebar">
            <div className="page__sidebar_header">
                <h2 className="clock-title bold">{tag}</h2>
            </div>
            <h1 style={{color: 'white', alignSelf: 'flex-end'}} className="bold text-center">
            { showtext == 'Y' ?(
                <span>Showroom / Procenter</span>): null}
              </h1>
            <div className="page__sidebar_content">
                <ClockSVG />
            </div>
            <h1 style={{color: 'white'}} className="bold text-center">
            { showtext == 'Y' ?(
                <span>{toTitleCase(paramValue.name)}</span>): null}
              
              </h1>
            <div className="page__sidebar_footer">
                { ln !== 'nl' && ln !== 'fr' ?(
                <img
                    src={placeholder ? placeholder : PlaceHolder}
                    className="sidebar-float"
                    alt=""
                    />):
                    ln === 'nl' ? (
                <img
                    src={localStorage.getItem('logoNL')}
                    alt=""
                    className={`sidebar-float nl`}
                />
                ) : ln === 'fr' ? (
                <img
                    src={localStorage.getItem('logoFR')}
                    alt=""
                    className={`sidebar-float fr`}
                />
                ) : null}


            </div>
        </div>
    )
}