import React from 'react';
import Sidebar from "../template/Sidebar";
import Copyright from '../template/Copyright';
import Home from '../../assets/img/ghor-icon.png'

import PlaceHolder from '../../assets/img/fr/fr-floating.jpeg'
import useQueryParameter from '../../hooks/getQueryParam';
import useQuery from '../../hooks/useQuery';
import useSummaryApiData from '../../hooks/useSummaryApiData';

export default function FrFifthPage(){
    var MainHomeClasses= useQueryParameter()
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const CurrentDate= `${new Date().getDate()}/${months[new Date().getMonth()]}/${new Date().getFullYear()}`
    const paramValue = useQuery("fr");
    const { data, loading, error } = useSummaryApiData(URL, paramValue);

    return(
        <>
            <div className={MainHomeClasses} style={{'background-image': 'url('+localStorage.getItem('bgImage')+')'}}>
                <div class="shadow"></div>
                <div className="grid">
                    <Sidebar ln="fr" placeholder={PlaceHolder} />
                    <div className="page__content">
                        <div className="box box-shadow light">
                            <div className="box__header">
                                <h2 className="title colored">Electricité verte pour la région</h2>
                                <h3 className="sub-title">
                                <div className='row'>
                                    <div className='col-lg-9'>Facq, prêt pour le futur</div>
                                        <div className='col-lg-3' style={{'text-align': 'end'}}>{CurrentDate}</div>
                                        </div>
                                </h3>
                                <div className="line"></div>
                            </div>

                            <div className="wrap">
                                <div className="wrapper">
                                    <h3 class="vh3">En 2023, l'équivalent de {parseInt(localStorage.getItem("HouseHold")).toLocaleString("de-DE")} ménages ont été alimentés avec l'énergie renouvelable produite par ce parc solaire.</h3>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <p style={{fontWeight: 500}}  class="mt-4 pfourth-Page">La quantité d'énergie produite, soit 2.995,601 MWh, correspond à la consommation énergétique de {parseInt(localStorage.getItem("HouseHold")).toLocaleString("de-DE")} ménages (un ménage comprend ici 3 personnes).</p>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <img style={{width: "80%"}} src={Home} className='house-img' alt="" />
                                        </div>
                                    </div>
                                </div>
                                <p>Consommation énergétique moyenne :<br></br>
                                Ménage de 3 personnes 4,29 kWh par an (100 m&sup2; de surface habitable)</p>
                            </div>

                            <Copyright ln="fr" />
                            
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}